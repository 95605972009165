/*
* news store
* qiufh@bocspace.com
*/
import {observable, action} from 'mobx';
import Config from '../../config';
import {requestPost} from "../../utils/api";
import journalSchemas from "../../utils/Schemas/journal";

class JournalStore {
	@observable newsList = [];
	@observable newsTotalCount = 0;
	@observable params = {
	"userId": "f4c03baa9ef93a11",
	"orderType": 0,
	"pageIndex": 1,
	"pageSize": 5
	}

    // 获取日志全部信息
	@action
	getNewsMainPagesByUserId() {
		return requestPost('getNewsMainPagesByUserId', Config.journal.getNewsMainPagesByUserId, journalSchemas.journal.getNewsMainPagesByUserId, this.params).then((res)=>{
			this.newsList = Object.assign([], res.body);
			this.params.pageIndex = res.pageIndex;
			this.params.pageSize = res.pageSize;
			this.newsTotalCount = res.totalCount;
        })
	}

	@observable newsDetail={}

	// 获取日志全部信息
	@action
	getNewsJournalById(param) {
		return requestPost('getNewsJournalById', Config.journal.getNewsJournalById, journalSchemas.journal.getNewsJournalById, param).then((res)=>{
			console.log('getNewsJournalById', res)
			this.newsDetail = Object.assign({},res)
		})
	}

	// 官网发送邮件
	@action
	sendOfficialEmail(param) {
		return requestPost('sendOfficialEmail', Config.journal.sendOfficialEmail, journalSchemas.journal.sendOfficialEmail, param)
	}

}

export default JournalStore;

