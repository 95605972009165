/*
 * @Author: your name
 * @Date: 2021-01-18 17:21:10
 * @LastEditTime: 2021-01-18 17:23:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/config.js
 */ 
/*
* 后端接口配置统一入口
* dangw@glodon.com
*/
let serverUrl = process.env.SERVER;
let requestHeader = "";


if (process.env.NODE_ENV === 'development') {
	serverUrl = 'http://127.0.0.1:8000';
} else {
	serverUrl = 'https://www.bocspace.cn';
}

  // serverUrl = 'https://test.bocspace.cn';

let Config = {

	// 数字组织会议日志记录
    journal: {
      getJournalTypeList: serverUrl + '/journal/getJournalTypeList' + requestHeader, // 获取日志类型列表,任务表的事项来源列表
      getJournalTagList: serverUrl + '/journal/getJournalTagList' + requestHeader, // 获取日志标签列表
      getJournalMainPages: serverUrl + '/journal/getJournalMainPages' + requestHeader, // saveType = 1草稿箱 saveType=2是提交的日志, readType 0 全部 1未读
      getJournalMainPage: serverUrl + '/journal/getJournalMainPage' + requestHeader, // saveType = 1草稿箱 saveType=2是提交的日志
      getJournalMainPageList: serverUrl + '/journal/getJournalMainPageList' + requestHeader, // orderType 0 发布时间最新，1，实际时间最新2实际时间最新倒序3为最热数降序，4为最热升序
      getJournalById: serverUrl + '/journal/getJournalById' + requestHeader, // 获取日志全部信息
      saveJournal: serverUrl + '/journal/saveJournal' + requestHeader, // journalMainid空为新增，此接口废弃，请勿再用
      getTemplateByType: serverUrl + '/journal/getTemplateByType' + requestHeader, // 根据类型获取模板
      delJournal: serverUrl + '/journal/delJournal' + requestHeader, // 删除日志
      saveJournalByType: serverUrl + '/journal/saveJournalByType' + requestHeader, // 保存日志，type=0：自动保存 1是点击草稿，2是提交日志,3是提交追加任务
      saveAttachment: serverUrl + '/journal/saveAttachment' + requestHeader, // 保存附件
      saveAttachments: serverUrl + '/journal/saveAttachments' + requestHeader, // 保存附件集
      sendEmail: serverUrl + '/journal/sendEmail' + requestHeader, // 发送邮件 attType[0,1] 0word 1email
      sendEmailByTiming: serverUrl + '/journal/sendEmailByTiming' + requestHeader, // 定时发送邮件 attType[0,1] 0word 1email
      cancelJournal: serverUrl + '/journal/cancelJournal' + requestHeader, // 取消本次编辑
      wordDownload: serverUrl + '/journal/wordDownload' + requestHeader, // 返回下载word的队列id
      pdfDownload: serverUrl + '/journal/pdfDownload' + requestHeader, // 返回下载pdf的队列id
      getDownloadUrl: serverUrl + '/journal/getDownloadUrl' + requestHeader, // 返回下载url
      delAttachment: serverUrl + '/journal/delAttachment' + requestHeader, // 删除附件
      getDocumentUrl: serverUrl + '/journal/getDocumentUrl' + requestHeader, // 获取预览url
      goBackJournal: serverUrl + '/journal/goBackJournal' + requestHeader, // 提交的日志回撤到草稿
      addComment: serverUrl + '/journal/addComment' + requestHeader, // 添加评论
      delComment: serverUrl + '/journal/delComment' + requestHeader, // 删除评论
      addOrDelLike: serverUrl + '/journal/addOrDelLike' + requestHeader, // 添加或者删除喜欢，也可以单独调用下面的添加或者删除接口
      addLike: serverUrl + '/journal/addLike' + requestHeader, // 添加喜欢
      delLike: serverUrl + '/journal/delLike' + requestHeader, // 删除喜欢
      getCommentsByJournalId: serverUrl + '/journal/getCommentsByJournalId' + requestHeader, // 获取日志评论列表
      getLikeUsersByJournalId: serverUrl + '/journal/getLikeUsersByJournalId' + requestHeader, // 获取日志点赞人列表
      getReadUsersByJournalId: serverUrl + '/journal/getReadUsersByJournalId' + requestHeader, // 获取日志阅读人列表
      getJournalIndicators: serverUrl + '/journal/getJournalIndicators' + requestHeader, // 获取日志浏览量等指标数据
      exportJournalDownload: serverUrl + '/journal/exportJournalDownload' + requestHeader, // 导出日志，type 1是日志记录2是日志内容，返回值为下载download对应id
      changeCanEdit: serverUrl + '/journal/changeCanEdit' + requestHeader, // 更改日志可编辑
      getNotReadCount: serverUrl + '/journal/getNotReadCount' + requestHeader, // 返回未读条数
      addOrDelCollection: serverUrl + '/journal/addOrDelCollection' + requestHeader, // 添加或者取消收藏
      getFileUploadInfo: serverUrl + '/journal/getFileUploadInfo' + requestHeader, // 返回oss上传的回调信息
      getSendUserEmails: serverUrl + '/journal/getSendUserEmails' + requestHeader, // 获取邮件输入人列表，type=0全部，type=1收件人，type=2抄送人
      getSharedEditingList: serverUrl + '/journal/getSharedEditingList' + requestHeader, // 获取日志的被共享人id列表
      saveSharedEditing: serverUrl + '/journal/saveSharedEditing' + requestHeader, // 保存编辑共享人
      getReleaseJournalById: serverUrl + '/journal/getReleaseJournalById' + requestHeader, // 获取发版后的日志
      doWeekExcelToImage: serverUrl + '/journal/doWeekExcelToImage' + requestHeader, // 返回下载任务id
      cancelUploadFileToAliyun: serverUrl + '/journal/cancelUploadFileToAliyun' + requestHeader, // 取消阿里云上传
      appendToAliyunData: serverUrl + '/journal/appendToAliyunData' + requestHeader, // 音频大文件追加上传
      getJournalMainPagesToWeChat: serverUrl + '/journal/getJournalMainPagesToWeChat' + requestHeader, // 微信日志列表
      getXzUrl: serverUrl + '/journal/getXzUrl' + requestHeader, // 返回协筑的链接，key为文档路径为空为文档主页
      getEmailTiming: serverUrl + '/journal/getEmailTiming' + requestHeader, // 返回是否有待发送定时邮件
      cancelEmailTiming: serverUrl + '/journal/cancelEmailTiming' + requestHeader, // 取消定时发送
      getAssignmentPageList: serverUrl + '/journal/getAssignmentPageList' + requestHeader, // state 进展 0未开始 1进行中2已完成3已逾期 orderValue 0 任务id，1提出时间2完成时间 orderType 1是升序，0是降序
      getMyInfo: serverUrl + '/journal/getMyInfo' + requestHeader, // 获取个人信息
      syncAssignment: serverUrl + '/journal/syncAssignment' + requestHeader, // 同步到任务列表
      delAssignment: serverUrl + '/journal/delAssignment' + requestHeader, // 删除列表任务
      saveAssignment: serverUrl + '/journal/saveAssignment' + requestHeader, // 保存任务
      deletedAssignment: serverUrl + '/journal/deletedAssignment' + requestHeader, // 删除日志内任务
      getAssignmentUser: serverUrl + '/journal/getAssignmentUser' + requestHeader, // 获取分配任务的人列表
      saveAssignments: serverUrl + '/journal/saveAssignments' + requestHeader, // 保存日志的任务，追加任务可以调用此接口，注意任务type=1, mainId为日志id
      getAssignmentPageListByRelationType: serverUrl + '/journal/getAssignmentPageListByRelationType' + requestHeader, // relationType='月思享'
      getNotifyPageList: serverUrl + '/journal/getNotifyPageList' + requestHeader, // type=0是未读1是已读，2 是全部
      setNotifyListRead: serverUrl + '/journal/setNotifyListRead' + requestHeader, // 
      setNotifyRead: serverUrl + '/journal/setNotifyRead' + requestHeader, // id是消息的id
      delNotifyList: serverUrl + '/journal/delNotifyList' + requestHeader, // 删除列表选中消息
      delNotify: serverUrl + '/journal/delNotify' + requestHeader, // 删除消息
      getNewNotifyCount: serverUrl + '/journal/getNewNotifyCount' + requestHeader, // 获取新（未读）消息条数
      saveSchedules: serverUrl + '/journal/saveSchedules' + requestHeader, // 保存日程
      getSchedulesById: serverUrl + '/journal/getSchedulesById' + requestHeader, // 获取详细日程
      getSchedulesByWeek: serverUrl + '/journal/getSchedulesByWeek' + requestHeader, // 获取日程列表
      getUserList: serverUrl + '/journal/getUserList' + requestHeader, // 获取人员列表
      delSchedulesById: serverUrl + '/journal/delSchedulesById' + requestHeader, // 删除
      getWeekByTime: serverUrl + '/journal/getWeekByTime' + requestHeader, // 根据日期获取周信息
      delMyTopUser: serverUrl + '/journal/delMyTopUser' + requestHeader, // 删除置顶
      saveMyTopUser: serverUrl + '/journal/saveMyTopUser' + requestHeader, // 添加置顶
      addDocument: serverUrl + '/journal/addDocument' + requestHeader, // 添加单个文档
      addDocuments: serverUrl + '/journal/addDocuments' + requestHeader, // 添加多个文档
      changeDocument: serverUrl + '/journal/changeDocument' + requestHeader, // 修改单个文档
      delDocument: serverUrl + '/journal/delDocument' + requestHeader, // 删除文档
      getDocumentTypeList: serverUrl + '/journal/getDocumentTypeList' + requestHeader, // 文档类型列表
      getDocumentListByTypeId: serverUrl + '/journal/getDocumentListByTypeId' + requestHeader, // 根据typeId 过滤文档列表
      getNewsJournalById: serverUrl + '/journal/getNewsJournalById' + requestHeader, // 获取日志全部信息
      getNewsMainPagesByUserId: serverUrl + '/journal/getNewsMainPagesByUserId' + requestHeader, // 获取新闻内容主表分页
      getTrainingPageList: serverUrl + '/journal/getTrainingPageList' + requestHeader, // 获取培训列表
      getTrainingPointsList: serverUrl + '/journal/getTrainingPointsList' + requestHeader, // 获取培训得分列表
      getTrainingDetailByUserId: serverUrl + '/journal/getTrainingDetailByUserId' + requestHeader, // 个人培训列表明细展示
      getTrainingById: serverUrl + '/journal/getTrainingById' + requestHeader, // 获取培训
      saveTraining: serverUrl + '/journal/saveTraining' + requestHeader, // 保存training
      delTraining: serverUrl + '/journal/delTraining' + requestHeader, // 删除training
      getDictListByType: serverUrl + '/journal/getDictListByType' + requestHeader, // 获取字典项
      getAllPeopleByMainId: serverUrl + '/journal/getAllPeopleByMainId' + requestHeader, // 所有人列表, 接待id
      getCustomerListByMainId: serverUrl + '/journal/getCustomerListByMainId' + requestHeader, // 获取客户列表
      saveReception: serverUrl + '/journal/saveReception' + requestHeader, // 发起保存接待 savetype1草稿2发起
      savePlan: serverUrl + '/journal/savePlan' + requestHeader, // 保存策划 
      delReception: serverUrl + '/journal/delReception' + requestHeader, // 删除策划
      getReceptionById: serverUrl + '/journal/getReceptionById' + requestHeader, // 获取策划
      getReceptionPlan: serverUrl + '/journal/getReceptionPlan' + requestHeader, // 获取策划详情
      getNeedDoReceptionCount: serverUrl + '/journal/getNeedDoReceptionCount' + requestHeader, // 获取显示需要代办的策划条数
      getReceptionPageList: serverUrl + '/journal/getReceptionPageList' + requestHeader, // 接待分页列表
      getDictStringListByType: serverUrl + '/journal/getDictStringListByType' + requestHeader, // String数组
      getParticipants: serverUrl + '/journal/getParticipants' + requestHeader, // 参与人列表
      getReceptionSetting: serverUrl + '/journal/getReceptionSetting' + requestHeader, // 默认设置（执行人）
      getReceptionExcelUrlById: serverUrl + '/journal/getReceptionExcelUrlById' + requestHeader, // url
      doReceptionExcel: serverUrl + '/journal/doReceptionExcel' + requestHeader, // taskid
      saveStarWall: serverUrl + '/journal/saveStarWall' + requestHeader, // 保存
      getStarWallById: serverUrl + '/journal/getStarWallById' + requestHeader, // 获取
      delStarWallById: serverUrl + '/journal/delStarWallById' + requestHeader, // 删除
      getPageStarWall: serverUrl + '/journal/getPageStarWall' + requestHeader, // 分页列表
      getStarWallListByMonth: serverUrl + '/journal/getStarWallListByMonth' + requestHeader, // yearMonth 202203 type1月2周
      setLike: serverUrl + '/journal/setLike' + requestHeader, // 点赞/取消点赞
      sendOfficialEmail: serverUrl + '/journal/sendOfficialEmail' + requestHeader, // 官网发送邮件
    }
}


export default Config;
