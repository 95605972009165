/*
 * @Author: your name
 * @Date: 2021-01-18 17:25:03
 * @LastEditTime: 2021-01-18 17:28:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/utils/api.js
 */ 
/*
* graphql封装请求 统一入口
* dangw@glodon.com
* */
import {message} from "antd";
// import GlobalStore from "store/GlobalStore";

/*
* 封装Graphql请求 全局loading
* */
export function requestPost(type, url, query, params) {
	// GlobalStore.showLoading();
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({
			query,
			variables: params
		})
	})
	.then(response => {
		// GlobalStore.hideLoading();
		return response.json();
	})
	.then((res) => {
		if (res.errors && res.errors.length > 0) {
			if (res.errors[0].code == "40101") {
				// 获取实例、重定向登录页
				window.location.href = window.location.origin+window.location.pathname+"#/login";
				return false;
			} else if (res.errors[0].code == "40103") {   // 登录没有权限
				// 获取实例、重定向登录页
				message.warn(res.errors[0].message||'没有权限');
				return false;
			} else {
				message.warn(res.errors[0].message || '请求失败')
			}
		} else {
			const data = res.data[type];
			if (data == null) {
				message.warn('服务器异常')
				return data;
			} else {
				return data;
			}
		}
	});
}

/*
* 封装Graphql请求 没有loading
* */
export function requestPost2(type, url, query, params) {
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({
			query,
			variables: params
		})
	})
	.then(response => {
		return response.json();
	})
	.then((res) => {
		if (res.errors && res.errors.length > 0) {
			if (res.errors[0].code == "40101") {
				// 获取实例、重定向登录页
				window.location.href = window.location.origin+window.location.pathname+"#/login";
				return false;
				//message.error(res.errors[0].message || '请求失败')
			} else {
				message.error(res.errors[0].message || '请求失败')
			}
		} else {
			const data = res.data[type];
			if (data == null) {
				message.error('服务器异常')
				return data;
			} else {
				return data;
			}
		}
	});
}
